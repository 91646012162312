<template>
  <div class="ld-pricing-section">
    <div class="inner-container fad">
      <div class="ld-pricing-section__flex">
        <div class="ld-pricing-section__card">
          <p class="h1 ld-pricing-section__card__title">
            {{ $t('pricing.free') }}
          </p>
          <p class="landing-download__subtitle ld-pricing-section__card__subtitle">
            {{ $t('pricing.freeDesc') }}
          </p>
          <div v-if="images?.[0]?.filename" class="ld-pricing-section__card__img">
            <ImageLazy :src="images[0].filename" :alt="images[0].alt" :title="images[0].title" />
          </div>
          <p class="h1 ld-pricing-section__card__price ld-pricing-section__card__price--free">
            {{ $t('pricing.free') }}
          </p>
          <p class="landing-download__subtitle ld-pricing-section__card__price-subtitle">
            {{ $t('pricing.noEngagement') }}
          </p>
          <ul class="ld-pricing-section__card__list">
            <li
              v-for="(feature, index) in features.slice(0, 6)"
              :key="index"
              class="ld-pricing-section__card__list__item"
            >
              <template v-if="feature.free">
                {{ feature.free }}
              </template>
              <template v-else>
                {{ feature }}
              </template>
            </li>
          </ul>
          <main-button
            :label="$t('actions.downloadApp')"
            :link="downloadAppLink"
            tag="a"
            target="_blank"
            class-variant="btn-blue full-width"
            class="u-bg-landing-color-primary"
            @click="trackDownloadLink"
          />
        </div>
        <div class="ld-pricing-section__card">
          <p class="h1 ld-pricing-section__card__title">
            {{ $t('pricing.plan', { n: selectedPlan.maxUsers }) }}
          </p>
          <p class="landing-download__subtitle ld-pricing-section__card__subtitle">
            {{ $t('pricing.planDesc') }}
          </p>
          <div v-if="images?.[1]?.filename" class="ld-pricing-section__card__img">
            <ImageLazy :src="images[1].filename" :alt="images[1].alt" :title="images[1].title" />
          </div>
          <p class="h1 ld-pricing-section__card__price">
            {{ $t('pricing.annualPrice', { price: localePrice(selectedPlan.annualPrice) }) }}
          </p>
          <p class="landing-download__subtitle ld-pricing-section__card__price-subtitle">
            {{ $t('pricing.monthlyPrice', { price: localePrice(selectedPlan.annualPrice / 12) }) }}
          </p>
          <div class="ld-pricing-section__card__count">
            <p class="landing-download__subtitle ld-pricing-section__card__subtitle">
              {{ $t('pricing.selectUsersCount') }}
            </p>
            <div class="ld-pricing-section__card__count__select">
              <select v-model="selectedPlanIndex">
                <option v-for="(plan, index) in plans" :key="index" :value="index">
                  {{ $t('pricing.usersCount', { min: plan.minUsers, max: plan.maxUsers }) }}
                </option>
              </select>
            </div>
          </div>
          <ul class="ld-pricing-section__card__list">
            <li v-for="(feature, index) in features" :key="index" class="ld-pricing-section__card__list__item">
              <template v-if="feature.plan">
                {{ feature.plan }}
              </template>
              <template v-else>
                {{ feature }}
              </template>
            </li>
          </ul>
          <main-button
            :label="$t('actions.contactUs')"
            :link="localePath('index')"
            class-variant="btn-blue full-width"
            class="u-bg-landing-color-primary"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Price from '@/mixins/price-mixin'
import DownloadApp from '@/mixins/download-app-mixin'
import MainButton from '@/components/Buttons/MainButton/MainButton'

export default {
  name: 'SbPricingPlan',
  components: {
    MainButton,
  },
  mixins: [Price, DownloadApp],
  props: {
    images: { type: Array, required: true },
  },
  data() {
    return {
      selectedPlanIndex: 0,
    }
  },
  computed: {
    selectedPlan() {
      return this.plans[this.selectedPlanIndex]
    },
    plans() {
      return [
        {
          title: 'PME10',
          minUsers: 0,
          maxUsers: 10,
          annualPrice: 240,
        },
        {
          title: 'PME20',
          minUsers: 11,
          maxUsers: 20,
          annualPrice: 360,
        },
        {
          title: 'PME50',
          minUsers: 21,
          maxUsers: 50,
          annualPrice: 500,
        },
      ]
    },
    features() {
      return [
        this.$t('pricing.stockManagement'),
        this.$t('pricing.marketplace'),
        this.$t('pricing.merchant'),
        {
          free: this.$t('pricing.userLimit', 1),
          plan: this.$t('pricing.userLimit', this.selectedPlan.maxUsers),
        },
        {
          free: this.$t('pricing.commissionOnSales', { commission: '30%' }),
          plan: this.$t('pricing.commissionOnSales', { commission: '20%' }),
        },
        {
          free: this.$t('pricing.oneStorage'),
          plan: this.$t('pricing.storageManagement'),
        },
        this.$t('pricing.alertManagement'),
        this.$t('pricing.bookingOption'),
      ]
    },
  },
}
</script>

<style lang="scss">
.ld-pricing-section {
  margin: 40px 0 60px 0;

  &__flex {
    @include mqmin($mq-sm) {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  &__card {
    background-color: var(--white);
    padding: 20px;
    border-radius: 20px;

    @include mqmin($mq-sm) {
      flex: 1 0 0px;
    }

    &:not(:last-child) {
      @include mqmin($mq-sm) {
        margin-right: 20px;
      }

      @include mq($mq-sm) {
        margin-bottom: 20px;
      }
    }

    &__title {
      margin-bottom: 0;
      color: var(--landing-color-primary);
    }

    &__img {
      margin-bottom: 20px;

      img {
        display: block;
        max-width: 100%;
      }
    }

    &__price {
      margin-bottom: 0;

      &--free {
        color: var(--light-grey);
      }
    }

    &__price-subtitle {
      color: var(--landing-color-secondary);
    }

    &__count {
      &__select {
        position: relative;
        display: flex;
        align-items: center;
        background-color: var(--landing-color-secondary);
        border-radius: 10px;
        margin-bottom: 20px;

        select {
          appearance: none;
          border: 0;
          background: transparent;
          color: var(--white);
          font-family: Avenir;
          font-size: pxToRem(15px);
          font-weight: 800;
          padding: 10px 68px 10px 20px;
          position: relative;
          z-index: 2;
          flex-grow: 1;

          @include mq($mq-md) {
            font-weight: 400;
          }
        }

        &::after {
          content: '';
          width: 28px;
          height: 28px;
          mask-image: url('@/assets/icons/ico-angle-down.svg');
          mask-position: contain;
          mask-repeat: no-repeat;
          background-color: var(--white);
          position: absolute;
          right: 5px;
          top: 50%;
          transform: translate(0, -50%);
          z-index: 1;
        }
      }
    }

    &__list {
      &__item {
        color: var(--steel-grey);
        font-size: pxToRem(15px);
        font-weight: 500;
        margin-bottom: 20px;

        @include mq($mq-md) {
          font-weight: 400;
        }

        strong {
          color: var(--landing-color-secondary);
          font-weight: 800;
        }

        &::before {
          content: '✅ ';
        }
      }
    }

    &__btn {
      margin-top: 20px;
    }
  }
}
</style>
